body {
    font-family: 'Source Sans Pro', sans-serif;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-move {
    cursor: move;
}

.input-group-icon-sizing {
    min-width: 42px;
}

.file-drop-zone {
    border-radius: 6px;
    border: 1px dashed #ced4da;
}

.modal-open {
  overflow: inherit;
}

.sort-highlight {
    margin-bottom: 17px;
    border-radius: 4px;
}

table.dataTable {
    margin: 0px !important;
}

.dataTables_wrapper {
    padding: 0px;
}

.dataTables_info, .dataTables_length {
    padding-top: 8px !important;
}

.dataTables_length {
    padding-top: 4px !important;
}

.dataTables_length label {
    margin: 0px !important;
}

.dataTables_length select {
    font-size: 15px;
    height: 36px !important;
    -webkit-appearance: none;
}

.form-card-access .input-group-prepend > .input-group-text {
    min-width: 150px;
}

.form-card-contact .input-group-prepend > .input-group-text {
    min-width: 130px;
}

.form-card-login-as-links .input-group-prepend > .input-group-text {
    min-width: 200px;
}

.form-card-login-as-links .input-group > .invalid-feedback {
    padding-left: 200px;
}

.form-alertic .input-group-prepend > .input-group-text {
    min-width: 200px;
}

select2 > .select2 > .selection > .select2-selection {
    min-height: 39px;
}

select2 > .select2 {
    width: 100% !important;
}

select2 {
    flex: 1 1 auto;
    width: 0 !important;
}

select2 > .select2 > .selection > .select2-selection > .select2-selection__rendered > .select2-selection__choice {
    padding: 0px 10px;
}

.input-group > select2 > .select2 > .selection > .select2-selection {
    min-height: 39px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.daterangepicker {
	box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
}

.select2-dropdown {
	box-shadow: 0px 0px 15px rgba(0,0,0,0.15);
}

.textarea-shadow {
	box-shadow: 0px 0px 10px rgba(0,0,0,0.05);
}

[disabled] {
    cursor: not-allowed !important;
}

.cursor-pointer {
	cursor: pointer;
}

.cards-max-size {
	max-width: 854px;
    width: 100%;
}

@media (min-width: 854px) {
	.card-content {
		height: 480px; 
	}
}

@media (max-width: 854px) {
	.card-content {
		min-height: 480px; 
	}
}

@media (max-width: 640px) {
	.card-content {
		min-height: 360px; 
	}
}

@media (max-width: 426px) {
	.card-content {
		min-height: 240px; 
	}
}

.card-content img {
	max-width: 854px;
	max-height: 480px; 
}

.card-content canvas {
	width: 100%; 
	height: 100%; 
    position: relative;
    top: 6px;
}

.no-text-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.importance-rating {
	font-size: 3rem;
}

.card-content-text {
	font-size: 1.75rem;
}

.card-front {
	background-color: #baffc9;
	text-shadow: -1px 0 rgba(255,255,255,0.7), 0 1px rgba(255,255,255,0.7), 1px 0 rgba(255,255,255,0.7), 0 -1px rgba(255,255,255,0.7);
}

.card-back {
	background-color: #bae1ff;
	text-shadow: -1px 0 rgba(255,255,255,0.7), 0 1px rgba(255,255,255,0.7), 1px 0 rgba(255,255,255,0.7), 0 -1px rgba(255,255,255,0.7);
}

.hidden-selectable-input {
	position: absolute;
    left: -9999px;
    top: 0;
}

.elearning-choose {
	list-style-type: none;
	counter-reset: elearning-choose-counter;
}

.elearning-choose li:before {
    content: counter(elearning-choose-counter, upper-alpha);
    counter-increment: elearning-choose-counter;
    margin-right: 5px;
    font-weight: bold;
}

.disabled {
    opacity: 0.6;
    cursor: no-drop;
}

.text-abbreviate { 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (min-width: 576px) {

    .text-abbreviate {
        max-width: 50px;
    }
}

@media (min-width: 768px) {

    .text-abbreviate {
        max-width: 100px;
    }
}

@media (min-width: 992px) {
    .text-abbreviate {
        max-width: 250px;
    }
}

@media (min-width: 1200px) {
    .text-abbreviate {
        max-width: 350px;
    }
}

    .d-xl-table-cell {
        display: none !important;
    }

@media (min-width: 1600px) {
    .d-xl-table-cell {
        display: table-cell !important;
    }
}

.td-min-width {
    width: 1px;
}

/* Sidebar fix */
.nav-sidebar>.nav-item {
    transition-timing-function: linear;
    transition: width 0.33s;
    -webkit-transition: width 0.33s;
}

.sidebar-open aside .nav-sidebar>.nav-item {
    transition: width 0.1s;
    -webkit-transition: width 0.1s;
    width: 234px;
}

.sidebar-collapse aside .nav-sidebar>.nav-item {
    transition: width 0.33s;
    -webkit-transition: width 0.33s;
    width: 58px;
}

aside:hover .nav-sidebar > .nav-item {
    transition: width 0.1s;
    -webkit-transition: width 0.1s;
    width: 234px;
}

.user-panel .info {
    line-height: 1.0;
    padding: 1px 5px 5px 10px;
}

.text-color-coral {
    color: #FF7F50;
}

.text-color-ruby {
    color: #E0115F;
}

.text-color-magenta {
    color: #FF00FF;
}

.text-color-cyan {
    color: #00FFFF;
}


.active > .nav-icon {
    color: #ffffff !important;
}

.nav-treeview .active > .nav-icon {
    color: #343a40 !important;
}

.widget {
    
}

.widget-slot {
    min-height: 80px;
    height: 100%;
    border: 1px dashed #dee2e6!important;
    background-color: #f8f9fa!important;
    border-radius: .25rem!important;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.015)!important;
}

.widget-delete {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.25)!important;
    position: absolute;
    display: none;
    margin: 4px 0px 0px 4px;
}

.widget:hover > .widget-delete {
    display: inline;
}

.border-dashed {
    border-style: dashed;
}

.bg-coral {
    background-color: #FF7F50!important;
}

.bg-magenta {
    background-color: #FF00FF!important;
}

.bg-cyan {
    background-color: #00FFFF!important;
}

.profile-user-img {
    margin: 0 auto;
    width: 100px !important;
    height: 100px !important;
    padding: 3px;
    border: 3px solid #adb5bd;
}

.card-words .card-body.p-0 .table tbody>tr>td:last-of-type, .card-body.p-0 .table tbody>tr>th:last-of-type, .card-body.p-0 .table thead>tr>td:last-of-type, .card-body.p-0 .table thead>tr>th:last-of-type, .pr-4, .px-4 {
    padding-right: 1.0rem!important;
}

.card-words .card-body.p-0 .table tbody>tr>td:first-of-type, .card-body.p-0 .table tbody>tr>th:first-of-type, .card-body.p-0 .table thead>tr>td:first-of-type, .card-body.p-0 .table thead>tr>th:first-of-type, .pl-4, .px-4 {
    padding-left: 1.0rem!important;
}

.select2-selection--single .select2-selection__rendered {
	margin-top: -2px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 28px !important;
    right: 3px !important;
    top: 3px !important;
}